<template>
    <b-row class="justify-content-center">
      <b-col class="col-md-6">
        <b-card border-variant="danger"
            class="m-0"
            header-bg-variant="danger"
            no-body>
          <div class="card-body text-center">
              <h3 class="text-danger">Plan Expired</h3>
              <p>Your subscription plan is expired, please contact to renew your plan.</p>
              <b-card
            border-variant="light"
            class="m-0"
            header-bg-variant="light"
            no-body
          >
            <template #header>
              <b-col>
                <b-row class="justify-content-between">
                  <b-col class="p-0 m-0">
                    <h5 class="font-weight-bolder m-0 text-dark">
                        Support
                    </h5>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">Call Us:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a href="tel:+919007632595" class="m-0">+91 9007632595</a>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">Email Us:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a href="mailto: support@mymunshi.in" class="m-0"
                  >support@mymunshi.in</a
                >
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">WhatsApp:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a target="_blank" href="https://wa.me/919007632595" class="m-0"
                  >+91 9007632595</a
                >
              </b-col>
            </b-row>
          </b-card>
          </div>
        </b-card>
        <account-setting-plan class="mt-1"></account-setting-plan>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import { BTabs, BTab, BOverlay, BCol, BRow, BCard } from 'bootstrap-vue'
  import AccountSettingPlan from './AccountSettingPlan.vue';
  
  export default {
    components: {
      BTabs,
      BTab,
      BCol,
      BRow,
      BCard,
      BOverlay,
      AccountSettingPlan
    }
  }
  </script>
  