<template>
  <b-card
    v-if="$store.state.settingStore.plansList.length"
    border-variant="primary"
    class="m-0 mb-1"
    no-body
    header-bg-variant="primary"
  >
    <template #header>
      <b-col>
        <b-row class="justify-content-between">
          <b-col class="p-0 m-0">
            <h5 class="font-weight-bolder m-0 text-white">Pricing</h5>
          </b-col>
        </b-row>
      </b-col>
    </template>
    <b-list-group>
      <b-list-group-item
        v-for="plan in $store.state.settingStore.plansList"
        :key="plan._id"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          <h6 class="mb-75">{{ convertDays(plan.period_days) }}</h6>
          <h4>
            &#8377;{{ plan.price
            }}<small v-if="!plan.gstIncluded"> + GST</small>
          </h4>
        </div>
        <a
          href="#"
          @click.prevent="handlePurchase(plan)"
          class="text-decoration-none"
        >
          <b-badge variant="primary" pill class="badge-round">
            Purchase
          </b-badge>
        </a>
      </b-list-group-item>
    </b-list-group>
  </b-card>
  <div v-else></div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import AccountSettingPlan from './AccountSettingPlan.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
    AccountSettingPlan
  },
  directives: {
    Ripple
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData'))
    }
  },
  beforeMount() {
    //   Court Setup
    this.$store
      .dispatch('settingStore/fetchPlans', { ctx: this })
      .then((response) => {
        console.log('Courts --> ', response)
        this.courtList = response
      })
  },
  methods: {
    getDate(date) {
      return moment(date).format('ll')
    },
    getFromNowDate(expires_at) {
      if (moment(expires_at).isBefore(moment())) {
        return 'Expired'
      }
      var days = moment(expires_at).endOf('day').fromNow()
      console.log(days)
      return days
    },
    convertDays(days) {
      const daysInYear = 365
      const daysInMonth = 30

      const years = Math.floor(days / daysInYear)
      const remainingDays = days % daysInYear
      const months = Math.floor(remainingDays / daysInMonth)

      if (years === 0 && months > 0) {
        return `${months} Month`
      } else if (years === 0 && months === 0) {
        return `${days} Days`
      } else if (months === 0) {
        return `${years} Year`
      } else {
        return `${years} Year, ${months} Month`
      }
    },
    handlePurchase(plan) {
      this.$store
        .dispatch('settingStore/generatePaymentLink', {
          ctx: this,
          payload: {
            plan_id: plan['_id']
          }
        })
        .then((response) => {
          if (response != null && response.success && response.data != null) {
            console.log('Payment Link --> ', response.data.url)
            const width = window.screen.width * 0.8
            const height = window.screen.height * 0.8
            const left = window.screen.width / 2 - width / 2
            const top = window.screen.height / 2 - height / 2

            this.popupWindow = window.open(
              response.data.url,
              'PopupWindow',
              `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`
            )
            // Start interval to check if the popup is closed
            this.popupCheckInterval = setInterval(() => {
              if (this.popupWindow && this.popupWindow.closed) {
                clearInterval(this.popupCheckInterval)
                this.handlePopupClose()
              }
            }, 500) // Check every 500ms
          }
        })
    },
    handlePopupClose() {
      // Perform any action you need on popup close
      console.log('Popup window has been closed.'),
        this.$store.dispatch('settingStore/getUser', {
          ctx: this
        })
      // Example: Notify the user, refresh data, etc.
    }
  }
}
</script>
